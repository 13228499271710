import React from 'react'
import {useIntl} from "gatsby-plugin-intl"
import {  graphql } from 'gatsby'
import Layout from '../components/layout'
import Header from '../components/header'
import InfoLine from '../components/infoline'



const ProductPage = (props) => {
  const intl = useIntl()
  return(
  <Layout>
    
    <Header 
      title='3D: Druck'
      text='SLA, SLS und FDM'
    />
    
    <div className='row'>
      <div className='col-12'>
      <p> {intl.formatMessage({ id: "index.UeberSchrift2" })}
      </p>
      </div>
    </div>

      <InfoLine
      image={props.data.imageOne}
      title='Design '
      /* subtitle= 'K 300-2'  */ 
      text='Sie haben ein Idee, eine Zeichnung, ein Objekt oder eine fertige CAD-Zeichnung: Gemeinsam entwickeln wir mit Ihnen das richtige Modell
      und beraten Sie der richtigen Formgebung.'
      />

      <InfoLine
      image={props.data.imageTwo}
      title='Scannen und Konstrieren'
      col='col-1'
      /* subtitle= 'K 300-4'  */ 
      text='Sollen vorhanden Objekte nachgebaut werden, werden diese eingescannt und ggf. nachgearbeitet. Auch unvollständige Teile können so wieder
      hergestellt werden'
      />
      <InfoLine
      image={props.data.imageThree}
      title='Drucken'
      col='col-2'
      /*subtitle= 'K 300-4'  */ 
      text='Mit unseren FDM-Drucker können wir bis zu 4 unterschiedliche Materialen drucken. Dadurch können unterschiedliche Materialqualitäten 
      inklusive Stützmaterial in einem Arbeitsgang gedruckt werden'
      />
      <InfoLine
      image={props.data.imageFour}
      title='Finish'
      col='col-3'
      /*subtitle= 'K 300-4'  */ 
      text='Je nach Drucktechnik können die Objekte nach dem Druck weiter optimiert werden:
      Oberflächenveredlung, Schleifen, Fräsen oder auch Lackieren: Zur weiteren Optimierung sind vielfältige Verfahren möglich'
      />
  

    </Layout>
)
  }


export default ProductPage;

//fluidImage is defined on imagePage
export const pageQuery = graphql`
  query {

    imageOne: file(relativePath: { eq: "CAD-Zeichnungen-RK-1.jpg" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "foto.png" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "CAD-Zeichnungen-RK-1.jpg" }) {
      ...fluidImage
    }
    imageFour: file(relativePath: { eq: "foto.png" }) {
      ...fluidImage
    }

  }
`